@font-face {
  font-family: "interBold";
  src: local("interBold"), url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "segoeui";
  src: local("segoeui"), url("../fonts/segoeui.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "segoeuib";
  src: local("segoeuib"), url("../fonts/segoeuib.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "segoeuii";
  src: local("segoeuii"), url("../fonts/segoeuii.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "segoeuil";
  src: local("segoeuil"), url("../fonts/segoeuil.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "segoeuisl";
  src: local("segoeuisl"), url("../fonts/segoeuisl.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "segoeuiz";
  src: local("segoeuiz"), url("../fonts/segoeuiz.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "seguibl";
  src: local("seguibl"), url("../fonts/seguibl.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "seguibli";
  src: local("seguibli"), url("../fonts/seguibli.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "seguili";
  src: local("seguili"), url("../fonts/seguili.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "seguisb";
  src: local("seguisb"), url("../fonts/seguisb.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "seguisbi";
  src: local("seguisbi"), url("../fonts/seguisbi.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "seguisli";
  src: local("seguisli"), url("../fonts/seguisli.ttf") format("truetype");
  font-weight: bold;
}

.font-face-gm {
  font-family: "interBold";
}
