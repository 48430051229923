body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.MuiPaper-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.MuiDialogContent-root {
  padding: 0% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: auto !important;
}
.modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #1d3463;
}
.modalItemsScroll::-webkit-scrollbar-thumb {
  background: #e61f43;
  border-radius: 20px;
}
.MuiSvgIcon-root {
  color: #707e9b;
}

.eth_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.eth_modalItemsScroll::-webkit-scrollbar {
  width: 5px;
}
.eth_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #d2dfff;
}
.eth_modalItemsScroll::-webkit-scrollbar-thumb {
  background: #f1620a;
  border-radius: 20px;
}

.pol_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.pol_modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.pol_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #e5d8ff;
}
.pol_modalItemsScroll::-webkit-scrollbar-thumb {
  background: #8247e5;
  border-radius: 20px;
}
.MuiButton-root:hover {
  text-decoration: none;
  /* background-color: #fff !important; */
}

.MuiDialog-paperScrollPaper {
  /* background: radial-gradient(
    167.49% 153.99% at 93.09% -4.01%,
    #000 0%,
    #000 100%
  ); */
  width: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  /* padding: 4px; */
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: #202a80 !important;
  padding: 20px !important;
  border-radius: 20px !important;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}

body > iframe {
  pointer-events: none;
}

.overlay::before,
.overlay::after {
  display: none !important;
}

.overlay {
  display: none !important;
}

.recharts-tooltip-wrapper:focus-visible{
  outline: none !important;
}
html{
  scroll-behavior: smooth;
}